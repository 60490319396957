import { useTranslation } from 'next-i18next';

import CopyBlock from '../copy-block/copy-block';

import { baseUrl } from '../../helpers/constants';
import { InformationStrip } from '../information-strip';

import type { GetHotelHomePageQuery } from '../../generated/types';
import type { InformationTileProps } from '../information-tile';
import Icon from '@dx-ui/osc-icon';

export type EventsInfoProps = {
  brandCode?: string;
  ctyhocn: string;
  eventsLink?: string;
  meetings: NonNullable<GetHotelHomePageQuery['hotel']>['meetings'];
  showMeetingsSimplified: boolean;
};

const EventsInfo = ({
  brandCode,
  ctyhocn,
  eventsLink,
  meetings,
  showMeetingsSimplified,
}: EventsInfoProps) => {
  const { t } = useTranslation('events');

  const informationTiles = (): InformationTileProps[] => {
    const tiles: InformationTileProps[] = [];
    const rfpUrl = meetings?.rfpUrl;

    if (rfpUrl) {
      tiles.push({
        ctaType: 'link',
        icon: <Icon name="cocktails" variant="solid" />,
        title: t('rfp.title'),
        children: t('rfp.text'),
        label: t('rfp.label'),
        url: rfpUrl,
      });
    }

    if (showMeetingsSimplified) {
      const meetingsSimplifiedUrl = `${baseUrl}/en/group-search/?query=${ctyhocn}&brandCode=${
        brandCode || ''
      }&numRooms=0&attendee_count=10`;

      tiles.push({
        ctaType: 'link',
        icon: <Icon name="meeting" variant="solid" />,
        title: t('meetingsSimplified.title'),
        children: t('meetingsSimplified.text'),
        label: t('meetingsSimplified.label'),
        url: meetingsSimplifiedUrl,
      });
    }

    return tiles;
  };

  return eventsLink ? (
    <CopyBlock
      headline={t('copyBlock.groupTravelAndEvents.groupTravelHeadline')}
      paragraphText={t('copyBlock.groupTravelAndEvents.groupTravelText')}
      primaryButton={{
        label: t('copyBlock.groupTravelAndEvents.groupPrimaryButton'),
        url: eventsLink,
        isNewWindow: false,
        brandComponentTheme: 'dark',
        variant: 'solid',
      }}
      theme="dark"
    />
  ) : (
    <>
      <CopyBlock
        headline={t('copyBlock.gatherYourGroup.gatherHeadline')}
        paragraphText={t('copyBlock.gatherYourGroup.gatherText')}
        primaryButton={{
          label: t('copyBlock.gatherYourGroup.gatherPrimaryButton'),
          url: `https://www.hilton.com/en/group-search/?query=${ctyhocn}&brandCode=${brandCode}&numRooms=10`,
          isNewWindow: true,
          brandComponentTheme: 'dark',
          variant: 'solid',
        }}
        secondaryButton={{
          label: t('copyBlock.gatherYourGroup.gatherSecondaryButton'),
          url: meetings?.hiltonLinkUrl ?? '',
          isNewWindow: true,
          brandComponentTheme: 'dark',
          variant: 'solid',
        }}
        theme="dark"
      />
      <InformationStrip tiles={informationTiles()} className="container" />
    </>
  );
};

export default EventsInfo;
