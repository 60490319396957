import cx from 'classnames';
import type { InformationTileProps } from '../information-tile';
import { InformationTile } from '../information-tile';

type InformationStripProps = {
  tiles: InformationTileProps[];
  displayInLine?: boolean;
  className?: string;
};

export const InformationStrip = ({
  tiles,
  displayInLine = false,
  className,
}: InformationStripProps) => {
  return (
    <div className={className} data-testid="InformationStrip">
      {tiles?.length <= 6 && tiles?.length > 0 && (
        <ul
          className={cx('flex flex-row gap-4 pt-4', {
            'flex-wrap pb-6': !displayInLine,
            'overflow-x-scroll snap-x snap-mandatory md:scrollbar-hide mb-4 pb-2': displayInLine,
          })}
        >
          {tiles.map((card) => (
            <li key={card.title} className="w-full min-w-[300px] flex-1 snap-start snap-always">
              <InformationTile
                ctaType={card.ctaType}
                headingClassName={card.headingClassName}
                icon={card.icon}
                label={card.label}
                title={card.title}
                url={card.url}
              >
                {card.children}
              </InformationTile>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
